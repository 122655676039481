import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { apiClient } from '../../auth/httpService';
import { useQuery } from '@tanstack/react-query';
import inviteR from '../../services/inviteR';
import PageBase from '../../components/PageBase';

const API_BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export default function Invite() {
  const {
    data,
    error,
    isLoading,
    refetch,
  } = useQuery(["invite"], inviteR.fetch);
  console.log(data);

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiClient.post(
        `${API_BASE_URL}/invite`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      refetch();
    } catch (error) {
      console.error('Ошибка загрузки файла', error);
    }
  };

  return (
    <PageBase>
      <div className="container mx-auto px-4 py-6">
        <Link to="/" className="underline bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600 active:bg-gray-700 focus:outline-none">
          Назад на главную
        </Link>
        <div className="w-full md:w-1/3 p-6">
          <h1 className="text-2xl font-semibold text-left text-gray-800 mb-4">
            Загрузить файл
          </h1>
          <form onSubmit={handleSubmit} className="flex flex-col items-start mb-6">
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              className="mb-4"
            />
            {file && <p className="mb-4 text-gray-600">{file.name}</p>}
            <button
              type="submit"
              className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
            >
              Загрузить файл
            </button>
          </form>
        </div>

        <div className="w-full">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">Нумерация</th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">Почта</th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">Код</th>
                  <th className="py-2 px-4 border-b border-gray-200 text-left">Статус</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item.id}>
                    <td className="py-2 px-4 border-b border-gray-200 text-left">{index + 1}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left">{item.email}</td>
                    <td className="py-2 px-4 border-b border-gray-200 text-left">{item.code}</td>
                    <td className={`py-2 px-4 border-b border-gray-200 text-left ${item.send ? 'text-green-500' : 'text-red-500'}`}>
                      {item.send ? 'Отправлен' : 'Не отправлен'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PageBase>
  );
}
