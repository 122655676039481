/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import trainerPackageService from "../../services/trainerPackage";
import PageBase from "../../components/PageBase";
import posts from "../../services/posts";

function TrainerPackageListPage() {
  const navigate = useNavigate();
  const {
    data: packages,
    error,
    isLoading,
  } = useQuery(["trainerPackage"], trainerPackageService.fetch);

  const {
    data: postsData,
    error: postsError,
    isLoading: postsIsLoading,
    refetch,
  } = useQuery(["posts"], posts.fetch);

  const handlePackageClick = (id) => {
    navigate(`/package/${id}`);
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation();
    navigate(`/edit-package/${id}`);
  };

  const {
    mutate: deletePost,
    isLoading: postDeleteLoading,
  } = useMutation(
    (item) => posts.deletePost({
      id: item.id,
      key: item.key
    }), {
    onSuccess: () => {
      refetch();
    }
  });

  const handleDelete = (item) => {
    deletePost(item);
  };

  return (
    <PageBase>
      <div className="flex-grow bg-gray-100">
        <div className="container mx-auto">
          <div className="py-6 flex flex-col justify-center sm:py-12">
            <div className="flex justify-start mb-4">
              <button
                onClick={() => navigate("/invite")}
                className="bg-green-500 text-white px-6 py-2 rounded shadow hover:bg-green-600 active:bg-green-700 focus:outline-none"
              >
                Приглашения
              </button>
            </div>

            <div>
              <div className="flex flex-row justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold text-center text-gray-800">
                  Пакеты
                </h1>
                <button
                  onClick={() => navigate("/create")}
                  className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
                >
                  Создать
                </button>
              </div>

              <div className="px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                {isLoading && (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                    <p className="ml-2">Загружаем...</p>
                  </div>
                )}

                {error && (
                  <div className="text-red-500">Что-то пошло не так.</div>
                )}

                {packages && (
                  <ul>
                    {packages.map((pkg) => (
                      <li
                        key={pkg.id}
                        className="relative mb-4 p-4 bg-gray-100 rounded shadow cursor-pointer hover:bg-gray-200"
                        onClick={() => handlePackageClick(pkg.id)}
                      >
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">
                          {pkg.name}
                        </h2>
                        <p className="text-gray-600">{pkg.description}</p>
                        <button
                          className="absolute top-2 right-2 bg-yellow-500 text-white rounded px-3 py-1 hover:bg-yellow-600"
                          onClick={(e) => handleEditClick(e, pkg.id)}
                        >
                          Редактировать
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="py-6 flex flex-col justify-center sm:py-12">
            <div>
              <div className="flex flex-row justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold text-center text-gray-800">
                  Посты
                </h1>
                <button
                  onClick={() => navigate("/createpost")}
                  className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
                >
                  Создать
                </button>
              </div>

              <div className="px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                {postsIsLoading && (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-purple-500"></div>
                    <p className="ml-2">Загружаем...</p>
                  </div>
                )}

                {postsError && (
                  <div className="text-red-500">Что-то пошло не так.</div>
                )}

                {postsData && (
                  <ul>
                    {postsData.map((pkg) => (
                      <li
                        key={pkg.id}
                        className="relative mb-4 p-4 bg-gray-100 rounded shadow cursor-pointer"
                      >
                        {postDeleteLoading && (
                          <div className="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center">
                            Пост удаляется...
                          </div>
                        )}
                        <button
                          className="absolute top-2 right-2 bg-red-500 text-white rounded px-3 py-1 hover:bg-red-600"
                          onClick={() => handleDelete(pkg)} // Предполагается, что handleDelete это функция удаления
                        >
                          Удалить
                        </button>
                        <img
                          src={pkg.thumbnailUrl}
                          alt="Package Image"
                          className="h-40 w-80 object-cover rounded mb-4"
                        />
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">
                          <a href={pkg.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                            {pkg.url}
                          </a>
                        </h2>
                        <p className="text-gray-600 mb-2">{pkg.description}</p>
                        <p className="text-gray-600">Продолжительность: {pkg.duration}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageBase>
  );
}

export default TrainerPackageListPage;
