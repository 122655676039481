/* eslint-disable import/no-anonymous-default-export */
import { apiClient } from "./http";

const API_BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

const create = async (submittedData) => {
  const formData = new FormData();

  Object.keys(submittedData).forEach((key) => {
    if (key !== "icon") {
      formData.append(key, submittedData[key]);
    }
  });

  if (submittedData.icon[0]) {
    formData.append("icon", submittedData.icon[0]);
  }

  const response = await apiClient.post(
    `${API_BASE_URL}/posts`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const fetch = async () => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/posts`
    );
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

const deletePost = async ({
  id,
  key,
}) => {
  console.log(id, key)
  try {
    const response = await apiClient.delete(
      `${API_BASE_URL}/posts/${id}`,
      { data: { key: key } }
    );
    return response.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

export default {
  create,
  fetch,
  deletePost
};
