import { apiClient } from "./http";

const API_BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

const fetch = async () => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/invite`
    );
    console.log(response.data.data)
    return response.data.data;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
};

export default {
  fetch,
};