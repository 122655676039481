import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import PageBase from "../../components/PageBase";

import trainerPackageService from "../../services/trainerPackage";

const TrainerPackageUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const mutation = useMutation(({ id, data }) => trainerPackageService.update(id, data), {
    onSuccess: (updatedPackage) => {
      navigate(`/`);
    },
  });

  const onSubmit = (submittedData) => {
    mutation.mutate({ id, data: submittedData });
  };

  const {
    data: packageDetail,
    error,
    isLoading,
    refetch,
  } = useQuery(["trainerPackage", id], () =>
    trainerPackageService.fetch({ id })
  );

  React.useEffect(() => {
    if (packageDetail) {
      setValue("name", packageDetail.name);
      setValue("description", packageDetail.description);
    }
  }, [packageDetail, setValue]);

  if (isLoading) {
    return (
      <PageBase>
        <div className="flex-grow bg-gray-100">
          <div className="container mx-auto"></div>
          <p>Загружаем...</p>
        </div>
      </PageBase>
    );
  }

  if (error) {
    return (
      <PageBase>
        <div className="flex-grow bg-gray-100">
          <div className="container mx-auto"></div>
          <p>Ошибка загрузки данных: {error.message}</p>
        </div>
      </PageBase>
    );
  }

  return (
    <PageBase>
      <div className="container mx-auto p-4">
        <a href="/" className="underline text-blue">К списку пакетов</a>
        <h2 className="text-2xl font-bold mt-8 mb-4">Редактировать пакет</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="name" className="block mb-2">
              Название пакета
            </label>
            <input
              type="text"
              {...register("name", {
                required: "Обязательное поле",
              })}
              className="p-2 border rounded w-full"
            />
            {errors.name && (
              <p className="text-red-500">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="description" className="block mb-2">
              Описание пакета
            </label>
            <textarea
              name="description"
              {...register("description", {
                required: "Обязательное поле",
              })}
              className="p-2 border rounded w-full"
            ></textarea>
            {errors.description && (
              <p className="text-red-500">{errors.description.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded"
          >
            Обновить
          </button>
        </form>

        <div className="mt-4">
          {mutation.isLoading ? <span>Обновляем...</span> : null}
          {mutation.isError ? (
            <div>Что-то пошло не так: {mutation.error.message}</div>
          ) : null}
          {mutation.isSuccess ? <div>Пакет обновлен.</div> : null}
        </div>
      </div>
    </PageBase>
  );
};

export default TrainerPackageUpdate;
